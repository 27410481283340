import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
  // {
  //   path: '/',
  //   name: 'test',
  //   component:() => import('@/views/test.vue'),
  // },
  {
    path: '/',
    name: 'login',
    component:() => import('@/views/Login.vue')
  },
  {
    // 重定向到personalCenter页面
    path: '/home',
    redirect:'/home/index',
    component:() => import('@/views/Home.vue'),
    children:[
      {
        path: 'index',
        component: () => import('@/views/homepage/index.vue'),
      },
      {
        path: 'JobInformation',
        component: () => import('@/views/homepage/JobInformation.vue'),
      },
      {
        path: 'TalentInformation',
        component: () => import('@/views/homepage/TalentInformation.vue'),
      },
      {
        path: 'MyOrder',
        component: () => import('@/views/homepage/MyOrder.vue'),
      },
      {
        path: 'MyPerformance',
        component: () => import('@/views/homepage/MyPerformance.vue'),
      },
      {
        path: 'occupationList',
        component: () => import('@/views/homepage/occupationList.vue'),
      },
      {
        path: 'jobListDetails',
        component: () => import('@/views/homepage/jobListDetails.vue'),
      },
      {
        path: 'announcement',
        component: () => import('@/views/homepage/announcement.vue'),
      },
      {
        path: 'announcementDetails',
        component: () => import('@/views/homepage/announcementDetails.vue'),
      },
      {
        path: 'recommend',
        component: () => import('@/views/homepage/recommend.vue'),
      },
      {
        path: 'uploadResume',
        component: () => import('@/views/homepage/uploadResume.vue'),
      },
      {
        path: 'viewResume',
        component: () => import('@/views/homepage/viewResume.vue'),
      },
      {
        path: 'previewResume',
        component: () => import('@/views/homepage/previewResume.vue'),
      },
      {
        // 经理端员工管理
        path: 'managerStaffManage',
        component: () => import('@/views/homepage/managerStaffManage.vue'),
      },
      {
        // 经理端我的业绩
        path: 'managerPerformance',
        component: () => import('@/views/homepage/managerPerformance.vue'),
      },
      {
        // 代理端员工管理
        path: 'agencyStaffManage',
        component: () => import('@/views/homepage/agencyStaffManage.vue'),
      },
      {
        // 代理端我的业绩
        path: 'agencyPerformance',
        component: () => import('@/views/homepage/agencyPerformance.vue'),
      },
      {
        // 申请提现
        path: 'withdraw',
        component: () => import('@/views/homepage/withdraw.vue'),
      },
      {
        path: 'mine',
        component: () => import('@/views/homepage/mine/index.vue'),
        redirect:'/home/mine/index',
        children:[
          {
            path: 'index',
            component: () => import('@/views/homepage/mine/minechild/index.vue'),
          },
          {
            path: 'editPawor',
            component: () => import('@/views/homepage/mine/minechild/editPawor.vue'),
          },
          {
            path: 'platform',
            component: () => import('@/views/homepage/mine/minechild/platform.vue'),
          },
        ]
      },
    ]
  },
]
const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
export default router
